import { faUnlock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Spinner, Tabs } from '@ufx-ui/core'
import axios from 'axios'
import cx from 'classnames'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import React, { useRef, useEffect, useState } from 'react'
import { Row, Col } from 'react-flexbox-grid'

import { apiURL } from '../../config'
import useWindowSize from '../../hooks/useWindowSize'
import { getEpisodeNum, getEpisodePercent } from '../../utils'
import AudioPlayer from '../AudioPlayer/AudioPlayer'
import Contribute from '../Contribute'
import ProgressBar from '../core/ProgressBar'
import EpisodeImage from '../EpisodeImage'
import Episodes from '../Episodes/Episodes'

const TABS = [
  {
    id: 'description',
    title: 'Description',
  },
  {
    id: 'transcript',
    title: 'Transcript',
  },
]

function EpisodeDetail({ podcast, morePodcasts }) {
  const {
    id,
    title,
    subtitle = '',
    episodeNumber,
    goal,
    charity,
    description,
    transcript: transcriptPath,
    avatar,
    src,
  } = podcast
  const [activeTab, setActiveTab] = useState(TABS[0].id)
  const [isShake, setIsShake] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [duration, setDuration] = useState(9)
  const percent = getEpisodePercent(podcast)
  const [, episodeNumStr] = getEpisodeNum(episodeNumber)
  const prevTitle = useRef(title)
  const { isMD: isMobile } = useWindowSize()
  const [transcript, setTranscript] = useState('')

  useEffect(() => {
    if (_isEmpty(transcriptPath)) {
      setTranscript(
        'Transcript will be available once episode is 100% Unlocked!'
      )
    } else {
      const fetchTranscript = async () => {
        if (transcriptPath) {
          const response = await axios.get(apiURL(transcriptPath))
          setTranscript(_get(response, 'data'))
        }
      }
      fetchTranscript()
    }
  }, [transcriptPath])

  useEffect(() => {
    if (title !== prevTitle) {
      setShowPaymentModal(false)
    }
  }, [title, prevTitle])

  if (_isEmpty(podcast)) {
    return <Spinner />
  }

  const shakeContributeBtn = shake => {
    if (shake) {
      setIsShake(true)
    }
  }

  return (
    <div className='episode-detail'>
      <Row className='info-row'>
        <Col sm={12} md={3}>
          <EpisodeImage
            avatar={avatar}
            episodeNum={episodeNumStr}
            title={title}
            showTitle={isMobile || showPaymentModal}
          />
        </Col>
        <Col
          sm={12}
          md={9}
          className={cx('content', {
            payment: showPaymentModal,
          })}
        >
          {!isMobile && !showPaymentModal && (
            <div className='info'>
              <div className='episode-no'>{episodeNumStr}</div>
              <div className='episode-guest'>{title}</div>
            </div>
          )}
          <div>
            <div className='heading-3'>{subtitle}</div>
            <div className='divider' />
          </div>
          {!showPaymentModal && (
            <Row>
              <Col md={12} lg={9} className='goal-contribute-row'>
                <div>
                  <ProgressBar
                    textStart={
                      <>
                        <div className='textEnd hidden-md2'>
                          Goal: {goal} sats
                        </div>
                        <div className='textEnd visible-md2'>Goal: {goal}</div>
                      </>
                    }
                    textEnd={
                      <div className='textEnd hidden-sm'>
                        <FontAwesomeIcon icon={faUnlock} />
                        <span>{percent.toFixed(1)}% Unlocked</span>
                      </div>
                    }
                    perc={percent}
                    className='unlocked-progress'
                  />
                </div>

                <Button
                  className={cx('contribute-btn', { shake: isShake })}
                  onAnimationEnd={() => setIsShake(false)}
                  intent='primary'
                  onClick={() => setShowPaymentModal(true)}
                >
                  Contribute
                </Button>
              </Col>
            </Row>
          )}

          {showPaymentModal && (
            <Contribute
              key={id}
              id={id}
              avatar={avatar}
              title={title}
              goal={goal}
              duration={duration}
              charity={charity}
              episodeNum={episodeNumStr}
              onClose={() => setShowPaymentModal(false)}
            />
          )}

          {(!showPaymentModal || isMobile) && (
            <Row className='actions'>
              <Col md={12} lg={9}>
                <AudioPlayer
                  src={apiURL(src)}
                  setDurationCallback={setDuration}
                  downloadable={percent >= 100}
                  paidPercentage={percent}
                  onEnded={shakeContributeBtn}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <br />
      <Row className='desc'>
        {isMobile ? (
          <>
            <Col sm={12} md={6}>
              <Tabs
                tabs={TABS}
                active={activeTab}
                onChange={value => setActiveTab(value)}
                containerClassName='episode-info-tabs'
              />
              <br />
              <p className='tab-content para-text transcript'>
                {activeTab === TABS[0].id ? description : transcript}
              </p>
            </Col>
          </>
        ) : (
          <>
            <Col sm={12} md={6}>
              <div className='heading-3'>Description</div>
              <div className='divider' />
              <p className='para-text'>{description}</p>
            </Col>
            <Col sm={12} md={6}>
              <div className='heading-3'>Transcript</div>
              <div className='divider' />
              <p className='para-text transcript'>{transcript}</p>
            </Col>
          </>
        )}
      </Row>

      {!_isEmpty(morePodcasts) && !isMobile && (
        <>
          <br />
          <Episodes heading='More Episodes' podcasts={morePodcasts} />
        </>
      )}
    </div>
  )
}

export default EpisodeDetail
